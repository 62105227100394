import { FC } from 'react';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import BlockViewportAnimation from '../../components/block-viewport-animation';
import { Image } from '../../content-provider';

interface Props {
  image: Image;
  backgroundStyle: BackgroundStyle;
}

const FullImageBlock: FC<Props> = (props: Props) => {
  return (
    <div className="FullImageBlock">
      <BackgroundStyleComponent background={props.backgroundStyle}>
        <BlockViewportAnimation className="container">
          <img src={props.image.url} alt="full image" />
        </BlockViewportAnimation>
      </BackgroundStyleComponent>
    </div>
  );
};

export default FullImageBlock;
