import cx from 'classnames';
import {
  axios,
  Maybe,
  MessageProgressComplete,
  MessageProgressFailed,
  MessageStatus,
  MessagingAPIForBrowser,
  waitFor,
} from 'lunr-core/browser';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { ProcessEarlyRequestFormResponse } from '../../../pages/api/process-early-access-request';
import Arrow from '../../components/arrow';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import BlockViewportAnimation from '../../components/block-viewport-animation';
import { Image } from '../../content-provider';
import { getMessagingAPIForBrowser } from '../../getMessagingAPIForBrowser';
import EarlyAccessRequestForm, {
  EarlyAccessRequestInput,
} from './EarlyAccessRequestForm';

interface Props {
  backgroundStyle: BackgroundStyle;
  image: Image;
  imageAlignment: 'Left' | 'Right';
  title: string;
  description: string;
  callToAction: string;
  successMessage: string;
}

enum FormState {
  CallToAction,
  Form,
  Submitted,
}

interface State {
  formState: FormState;
}

export default class EarlyAccessRequestBlock extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formState: FormState.CallToAction,
    };
  }

  render = () => {
    return (
      <div className="EarlyAccessRequestBlock">
        <BackgroundStyleComponent
          background={this.props.backgroundStyle}
          className={cx('EarlyAccessRequestBlock--inner')}
        >
          <BlockViewportAnimation className="container">
            <div
              className={cx(
                'EarlyAccessRequestBlock--grid gw',
                this.props.imageAlignment === 'Right' && 'gw--rev'
              )}
            >
              <div
                className={cx(
                  'EarlyAccessRequestBlock--imageHalf g desk-one-half'
                )}
              >
                <img
                  className="EarlyAccessRequestBlock--media EarlyAccessRequestBlock--mediaImage"
                  src={this.props.image.url}
                  alt={this.props.image.alt}
                />
              </div>
              <div
                className={cx(
                  'EarlyAccessRequestBlock--textHalf g desk-one-half'
                )}
              >
                <h5 className="EarlyAccessRequestBlock--title">
                  {this.props.title}
                </h5>
                <p className="EarlyAccessRequestBlock--description">
                  {this.props.description}
                </p>

                <AnimateHeight
                  height={this.state.formState === FormState.Form ? 'auto' : 0}
                >
                  <EarlyAccessRequestForm
                    backgroundStyle={this.props.backgroundStyle}
                    onSubmit={this.onFormSubmit}
                  />
                </AnimateHeight>

                <AnimateHeight
                  height={
                    this.state.formState === FormState.Submitted ? 'auto' : 0
                  }
                >
                  <p className="EarlyAccessRequestBlock--submitted">
                    {this.props.successMessage}
                  </p>
                </AnimateHeight>

                <AnimateHeight
                  height={
                    this.state.formState === FormState.CallToAction ? 'auto' : 0
                  }
                >
                  <button
                    className="EarlyAccessRequestBlock--anchor btn--chromeless"
                    onClick={this.onRequestForm}
                  >
                    {this.props.callToAction}
                    <Arrow className="EarlyAccessRequestBlock--anchorArrow" />
                  </button>
                </AnimateHeight>
              </div>
            </div>
          </BlockViewportAnimation>
        </BackgroundStyleComponent>
      </div>
    );
  };

  onRequestForm = () => {
    this.setState({ formState: FormState.Form });
  };

  onFormSubmit = async (input: EarlyAccessRequestInput): Promise<boolean> => {
    let response = null;
    try {
      response = await axios.post<ProcessEarlyRequestFormResponse>(
        '/api/process-early-access-request',
        input,
        {
          headers: {
            'api-key': process.env.NEXT_PUBLIC_API_KEY!,
          },
        }
      );
    } catch (err) {
      console.warn('Error submitting form data!', err);
    }

    if (!response || response.status !== 200 || !response.data.messageID) {
      return false;
    }

    const messagingAPI: MessagingAPIForBrowser = getMessagingAPIForBrowser();
    const progress: Maybe<MessageProgressComplete | MessageProgressFailed> =
      await messagingAPI.waitForProgress(response.data.messageID);
    if (!progress || progress.status !== MessageStatus.COMPLETED) {
      return false;
    }

    this.setState({ formState: FormState.Submitted }, () => {
      waitFor(5000)
        .then(() => {
          this.setState({ formState: FormState.CallToAction });
        })
        .catch((err) => {
          console.warn(err);
        });
    });

    return true;
  };
}
