import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  axios,
  createRecaptchaToken,
  LoadingSpinner,
  LoadingSpinnerSize,
  Modal,
  RecaptchaIcon,
} from 'lunr-core/browser';
import NextLink from 'next/link';
import { FC, useState } from 'react';
import {
  NewsletterSubscriptionErrorResponse,
  NewsletterSubscriptionSuccessResponse,
} from '../../../pages/api/newsletter-subscription';
import { NavigationLink } from '../../content-provider';
import {
  NewsletterSubscriptionValues,
  validateNewsletterSubscriptionValues,
} from '../../validations/newsletterSubscription';
import styles from './GetStartedModal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  applyNowLink: NavigationLink | null;
};

const formInitialValues: NewsletterSubscriptionValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const sendFormRequest = async (values: NewsletterSubscriptionValues) => {
  try {
    if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
      throw new Error('Recaptcha not configured.');
    }

    const recaptcha: string = await createRecaptchaToken(
      process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      'newsletter_subscription_form'
    );

    const { data } = await axios.post<NewsletterSubscriptionSuccessResponse>(
      '/api/newsletter-subscription',
      {
        ...values,
        recaptcha,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.NEXT_PUBLIC_API_KEY!,
        },
      }
    );

    return data.message;
  } catch (err) {
    const defaultErrMessage =
      'Failed to subscribe email. Please try again later.';
    if (axios.isAxiosError(err)) {
      const errMessage =
        (err.response?.data as NewsletterSubscriptionErrorResponse).error ||
        defaultErrMessage;
      throw new Error(errMessage);
    }

    throw new Error(defaultErrMessage);
  }
};

const GetStartedModal: FC<Props> = ({ open, onClose, applyNowLink }) => {
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const onCloseHandler = () => {
    setIsFormLoading(false);
    setSuccessMessage(null);
    onClose();
  };

  return open ? (
    <Modal
      className={styles.GetStartedModal}
      onClose={onCloseHandler}
      allowClose={!isFormLoading}
    >
      <h2 className={styles.Title}>
        Get the <span>latest insights!</span>
      </h2>
      <h3 className={styles.SubTitle}>
        Join Our Mailing List or{' '}
        <NextLink
          href={applyNowLink?.url || '/'}
          target={applyNowLink?.target || undefined}
        >
          Apply Now!
        </NextLink>
      </h3>
      {successMessage === null ? (
        <NewsletterSubscriptionForm
          isLoading={isFormLoading}
          setIsLoading={(isLoading) => setIsFormLoading(isLoading)}
          onSuccessMessage={(message) => setSuccessMessage(message)}
        />
      ) : (
        <h3 className={styles.SubTitle}>{successMessage}</h3>
      )}
    </Modal>
  ) : null;
};

type NewsletterSubscriptionFormProps = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onSuccessMessage: (message: string) => void;
};

const NewsletterSubscriptionForm: FC<NewsletterSubscriptionFormProps> = ({
  isLoading,
  setIsLoading,
  onSuccessMessage,
}) => {
  const [error, setError] = useState<string | null>(null);

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={async (values: NewsletterSubscriptionValues) => {
        setIsLoading(true);

        try {
          const message = await sendFormRequest(values);
          onSuccessMessage(message);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('Unexpected error, please try again later.');
          }
        }

        setIsLoading(false);
      }}
      validate={validateNewsletterSubscriptionValues}
    >
      <Form>
        <div className={styles.FormFields}>
          <div className={styles.NameFields}>
            <div>
              <Field
                id="firstName"
                className="form-control"
                type="text"
                name="firstName"
                placeholder="First Name"
              />
              <span className={styles.FieldError}>
                <ErrorMessage name="firstName" />
              </span>
            </div>
            <div>
              <Field
                id="lastName"
                className="form-control"
                type="text"
                name="lastName"
                placeholder="Last Name"
              />
              <span className={styles.FieldError}>
                <ErrorMessage name="lastName" />
              </span>
            </div>
          </div>
          <div>
            <Field
              id="email"
              className="form-control"
              type="email"
              name="email"
              placeholder="Email Address"
            />
            <span className={styles.FieldError}>
              <ErrorMessage name="email" />
            </span>
          </div>
          {error !== null ? (
            <div className={styles.FormError}>{error}</div>
          ) : null}
          <div className={styles.FormFooter}>
            <div>
              <RecaptchaIcon />
            </div>
            <div>
              <button
                className="btn btn--white"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <LoadingSpinner size={LoadingSpinnerSize.Tiny} />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default GetStartedModal;
