import cx from 'classnames';
import { FC } from 'react';
import Anchor from '../../components/anchor';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import BlockViewportAnimation from '../../components/block-viewport-animation';
import { Link } from '../../content-provider';

type CallToActionStyle = 'Button' | 'Link';

interface Props {
  text: string;
  style: CallToActionStyle;
  link: Link;
  backgroundStyle: BackgroundStyle;
}

const CallToActionBlock: FC<Props> = (props: Props) => {
  return (
    <div className="CallToActionBlock">
      <BackgroundStyleComponent background={props.backgroundStyle}>
        <BlockViewportAnimation className="container">
          {renderCTA(props.link, props.style, props.backgroundStyle)}
        </BlockViewportAnimation>
      </BackgroundStyleComponent>
    </div>
  );
};

const renderCTA = (
  link: Link,
  linkStyle: CallToActionStyle,
  backgroundStyle: BackgroundStyle
) => {
  if (link) {
    switch (linkStyle) {
      case 'Link':
        return (
          <Anchor
            className={cx(
              backgroundStyle === BackgroundStyle.Purple && 'a--white a--btn'
            )}
            link={link}
            forceArrow={true}
          />
        );
      case 'Button':
        return (
          <Anchor
            className={cx(
              'btn',
              backgroundStyle === BackgroundStyle.Purple && 'btn--white'
            )}
            link={link}
          />
        );
      default:
        return null;
    }
  }
};

export default CallToActionBlock;
