import cx from 'classnames';
import React from 'react';
import ViewportObserverOneTime from '../viewport-observer-onetime';

interface Props {
  className?: string;
  children: React.ReactNode;
}

interface State {}

/**
 * This is the class that generically handles all of the block intro animations.
 */
export default class BlockViewportAnimation extends React.Component<
  Props,
  State
> {
  render = () => {
    return (
      <ViewportObserverOneTime
        className={cx(this.props.className, 'BlockViewportAnimation')}
        classNameObserved="BlockViewportAnimation--visible"
      >
        {this.props.children}
      </ViewportObserverOneTime>
    );
  };
}
