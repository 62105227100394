import cx from 'classnames';
import NextLink from 'next/link';
import { FC, MouseEventHandler } from 'react';
import { Link } from '../../content-provider';
import isExternalURL from '../../isExternalURL';
import Arrow from '../arrow';

interface Props {
  className?: string;
  noAnimation?: boolean;
  link: Link;
  forceArrow?: boolean;
  ignoreArrow?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const Anchor: FC<Props> = (props: Props) => {
  return (
    <NextLink
      className={cx(
        'Anchor',
        props.className,
        props.noAnimation && 'a--noAnimation'
      )}
      href={props.link.url}
      target={props.link.target || undefined}
      onClick={props.onClick}
    >
      {props.link.text}

      {!props.ignoreArrow &&
        (props.forceArrow ||
          props.link.target === '_blank' ||
          isExternalURL(process.env.NEXT_PUBLIC_SITE_URL!, props.link.url)) && (
          <Arrow className="Anchor--arrow" />
        )}
    </NextLink>
  );
};

export default Anchor;
