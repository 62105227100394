import cx from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
}

const LogoIcon: FC<Props> = (props) => {
  return (
    <svg
      className={cx('LogoIcon', props.className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 335.83 250.66"
      aria-label="The Lunr logo icon; a mobius strip."
    >
      <g>
        <path
          fill="currentColor"
          d="M327.49,56.32c8.79,10.19,8.39,15.77,8.15,19.11-.04,.48-.06,.9-.06,1.27,0,23.37-9.13,44.73-27.91,65.28-18.71,20.48-47.78,41.01-88.87,62.76-.08,.04-.17,.09-.25,.13,.2-.12,.29-.38,.18-.59-.11-.22-.38-.31-.6-.2,.1-.05,.18-.08,.26-.13,41-21.7,69.99-42.18,88.63-62.58,18.62-20.39,27.67-41.54,27.67-64.68,0-.41,.03-.86,.07-1.34,.23-3.2,.62-8.56-7.93-18.45l-31.24-34.6c2.49,4.56,5.27,11.63,6.64,21.79-.04-.19-.19-.34-.39-.36-.19-.02-.38-.04-.57-.06-1.98-14.01-6.66-21.9-9.31-25.39l-2.64-2.93h0s0-.01,0-.01c-9.93-9.93-20.76-14.36-35.1-14.36-31.57,0-85.36,12.52-130.8,30.45-32.9,12.98-90.44,40.3-106.19,77.41-.47,1.11-2.86,7.36-3.19,8.5-5.37,18.61-9.05,32.04-9.86,36-.29,.31-.57,.62-.84,.93,0,0,0,0,0,.01-.02,.02-.03,.03-.04,.05,0,0-.01,.02-.01,.02-.02,.03-.03,.06-.03,.08,0,0,0,.02,0,.02,0,.01,0,.03,0,.04-.02,.08-.04,.16-.06,.25-.17-.65,.34-3.24,3.91-16.15,2.32-8.39,5.03-17.84,6.09-21.51,.35-1.2,2.73-7.45,3.22-8.6,6.29-14.83,19.8-29.5,40.16-43.62,17.8-12.35,40.18-23.88,66.52-34.27,23.19-9.15,48.31-16.92,72.63-22.45C218.55,2.96,239.33,.1,254.2,.1s25.62,4.51,35.73,14.62h0c.24,.2,1.28,1.12,2.7,3l34.84,38.6Z"
        />
        <path
          fill="currentColor"
          d="M326.82,56.92c8.54,9.89,8.15,15.25,7.93,18.45-.04,.47-.07,.93-.07,1.34,0,23.14-9.05,44.29-27.67,64.68-18.64,20.4-47.63,40.87-88.63,62.58-.07,.04-.16,.08-.26,.13,0,0,0,0,0,0-2.49,1.26-5.35,2.73-8.38,4.29-2.83,1.46-6.04,3.12-9.4,4.83-.05,.03-.11,.05-.16,.08-8.27,4.11-17.06,8.32-26.2,12.55,6.56-4.28,13.01-8.75,19.24-13.3,.01,0,.02-.01,.03-.02,0,0,.02-.01,.02-.02,22.26-16.27,42.05-33.94,58.83-52.51,16.45-18.21,29.25-36.43,38.04-54.17,.57-1.14,4.71-10.28,5.98-13.64,4.63-12.22,6.98-23.74,6.98-34.24,0-4.84-.29-9.5-.87-13.83,0,0,0-.01,0-.02-1.37-10.16-4.15-17.23-6.64-21.79l31.24,34.6Z"
        />
        <path
          fill="currentColor"
          d="M302.23,44.12c.58,4.33,.87,8.99,.87,13.83,0,10.5-2.35,22.03-6.98,34.24-1.27,3.36-5.41,12.5-5.98,13.64-8.79,17.74-21.59,35.96-38.04,54.17-16.78,18.57-36.57,36.23-58.83,52.51,0,0-.02,.01-.02,.02h0c.16-.11,.24-.3,.18-.49-.06-.24-.31-.38-.55-.32-.02,0-.04,.01-.06,.02,22.18-16.22,41.9-33.83,58.62-52.33,16.4-18.15,29.15-36.3,37.91-53.97,.57-1.13,4.68-10.22,5.95-13.56,4.59-12.11,6.92-23.53,6.92-33.92,0-4.68-.27-9.17-.82-13.36,.12,.01,.24,.02,.35,.04,.25,.02,.47-.15,.49-.4,0-.04,0-.07,0-.11Z"
        />
        <path
          fill="currentColor"
          d="M302.23,44.12s0,.07,0,.11c-.02,.25-.25,.42-.49,.4-.12-.01-.24-.02-.35-.04-.01-.12-.03-.23-.04-.35-.02-.19-.05-.37-.08-.56,.19,.02,.38,.04,.57,.06,.2,.02,.36,.17,.39,.36,0,0,0,.01,0,.02Z"
        />
        <path
          fill="currentColor"
          d="M301.34,44.24c.02,.12,.03,.23,.04,.35-2.57-.26-5.33-.38-8.44-.38-29.75,0-67.31,15.35-114.87,34.78-17.28,7.06-35.15,14.36-54.48,21.69-11.68,4.42-23.35,8.5-34.64,12.45-37.21,13-69.38,24.23-84.76,41.52-.04-.07-.08-.15-.11-.22l-.02-.05s0,0,0-.01c0,0,0,0,0-.01-.01-.02-.02-.04-.04-.06,0,0,0,0,0,0,.02-.2,.07-.52,.16-.94,15.78-17.06,47.67-28.21,84.5-41.07,11.28-3.94,22.95-8.01,34.62-12.44,19.32-7.32,37.18-14.62,54.46-21.68,47.65-19.47,85.28-34.85,115.21-34.85,3.05,0,5.78,.12,8.32,.37,.02,.18,.05,.37,.08,.56Z"
        />
        <path
          fill="currentColor"
          d="M301.26,43.68c-2.53-.25-5.26-.37-8.32-.37-29.92,0-67.56,15.38-115.21,34.85-17.27,7.06-35.13,14.36-54.46,21.68-11.67,4.42-23.34,8.5-34.62,12.44-36.82,12.86-68.72,24.01-84.5,41.07,.81-3.96,4.49-17.39,9.86-36,.33-1.14,2.71-7.39,3.19-8.5C32.97,71.75,90.51,44.43,123.4,31.45,168.84,13.52,222.63,1,254.2,1c14.35,0,25.17,4.43,35.1,14.36h0s0,0,0,.01l2.64,2.93c2.64,3.49,7.33,11.38,9.31,25.39Z"
        />
        <path
          fill="currentColor"
          d="M218.72,204.29c.11,.21,.03,.47-.18,.59-.01,0-.03,.02-.04,.02-.03,.02-.06,.03-.08,.04-2.46,1.24-5.28,2.7-8.27,4.24-2.78,1.43-5.92,3.05-9.2,4.73-.07,.03-.14,.07-.21,.1-10.05,4.99-20.87,10.14-32.17,15.3-.06,.03-.12,.04-.18,.04-.16,0-.31-.08-.39-.23-.08-.15-.07-.33,.02-.46,12-5.27,23.03-10.73,32.16-15.37,.05-.03,.11-.05,.16-.08,6.21-3.09,12.19-6.16,17.78-9.12,0,0,0,0,0,0,.22-.11,.49-.02,.6,.2Z"
        />
        <path
          fill="currentColor"
          d="M210.15,209.19c2.99-1.54,5.81-3,8.27-4.24-5.48,2.9-11.37,5.92-17.47,8.97,3.28-1.68,6.42-3.3,9.2-4.73Z"
        />
        <path
          fill="currentColor"
          d="M209.73,208.39c3.03-1.57,5.89-3.04,8.38-4.29-5.59,2.96-11.57,6.03-17.78,9.12,3.36-1.71,6.57-3.37,9.4-4.83Z"
        />
        <path
          fill="currentColor"
          d="M193.42,212.04c.05,.19-.03,.38-.18,.49-.04,.02-.08,.05-.14,.06-.76,.2-1.51,.39-2.25,.57,.63-.45,1.26-.9,1.88-1.36,.02-.01,.03-.02,.05-.03,.01,0,.02-.02,.03-.02,.02,0,.04-.01,.06-.02,.24-.06,.49,.08,.55,.32Z"
        />
        <path
          fill="currentColor"
          d="M193.24,212.52h0s-.02,.02-.03,.02c-6.23,4.56-12.68,9.02-19.24,13.3,9.14-4.23,17.94-8.44,26.2-12.55-9.13,4.64-20.16,10.1-32.16,15.37,.03-.05,.07-.1,.13-.13,7.73-4.9,15.37-10.07,22.71-15.39,.74-.18,1.49-.37,2.25-.57,.05-.01,.1-.04,.14-.06Z"
        />
        <path
          fill="currentColor"
          d="M192.81,211.73s-.02,.02-.03,.02c-.02,0-.04,.02-.05,.03-.62,.46-1.25,.91-1.88,1.36-14.24,3.54-26.36,4.99-41.23,4.99-.97,0-2.23,.02-3.68,.04-2.15,.04-4.82,.08-7.93,.08-14.02,0-36.87-.86-60.32-7.19-35.26-9.53-60.1-28.08-73.85-55.15,.08-.37,.16-.75,.25-1.12,.04-.05,.08-.09,.12-.14,13.54,27.3,38.34,45.99,73.71,55.55,28.52,7.71,56.19,7.27,68.01,7.09,1.46-.02,2.72-.04,3.7-.04,15.55,0,28.07-1.6,43.2-5.52Z"
        />
        <path
          fill="currentColor"
          d="M168.56,229.32c11.3-5.16,22.12-10.31,32.17-15.3-14.23,7.23-33.11,16.49-53.07,23.89-22.66,8.4-42.23,12.65-58.19,12.65-10,0-25.1-1-40.24-5.76-15.53-4.89-27.61-12.62-35.9-22.99C.29,205.51-3.13,182.95,3.16,154.77c.04,.15,.12,.2,.21,.25,0,0,0,0,0,0,.15,.3,.3,.6,.45,.9-5.95,27.46-2.52,49.43,10.2,65.33,8.18,10.22,20.11,17.86,35.48,22.69,15.03,4.73,30.03,5.72,39.97,5.72,15.85,0,35.32-4.24,57.87-12.6,7.08-2.62,14.02-5.48,20.67-8.4-.09,.13-.1,.31-.02,.46,.08,.15,.23,.23,.39,.23,.06,0,.12-.01,.18-.04Z"
        />
        <path
          fill="currentColor"
          d="M190.85,213.15c-7.34,5.32-14.98,10.48-22.71,15.39-.06,.04-.1,.08-.13,.13-6.65,2.92-13.59,5.77-20.67,8.4-22.55,8.36-42.03,12.6-57.87,12.6-9.94,0-24.94-1-39.97-5.72-15.36-4.83-27.3-12.47-35.48-22.69C1.31,205.35-2.12,183.38,3.83,155.91c13.75,27.07,38.59,45.63,73.85,55.15,23.44,6.33,46.29,7.19,60.32,7.19,3.11,0,5.78-.04,7.93-.08,1.45-.02,2.71-.04,3.68-.04,14.88,0,26.99-1.45,41.23-4.99Z"
        />
        <path
          fill="currentColor"
          d="M4.2,154.66s-.08,.09-.12,.14c0-.04,.02-.08,.02-.11,.01-.05,.01-.11,0-.17,0-.02,0-.05-.02-.07,.04,.07,.07,.15,.11,.22Z"
        />
        <path
          fill="currentColor"
          d="M4.1,154.51c0,.06,0,.12,0,.17-.02,.07-.05,.14-.1,.2-.09,.1-.21,.15-.34,.15-.1,0-.21-.04-.29-.11-.05-.04-.09-.1-.12-.15,0,0,0,0,0-.01,0-.02-.02-.05-.02-.08-.01-.05-.01-.1,0-.15,0-.01,0-.03,0-.04,0,0,0-.02,0-.02,0-.03,.02-.06,.03-.08v-.02s.03-.04,.05-.05c.01-.02,.03-.03,.04-.04,.01-.01,.03-.02,.04-.04l.06-.04h0s.05-.02,.08-.03c.02,0,.03,0,.04,0,.04,0,.09,0,.13,0h.06s.04,.02,.06,.03c.02,0,.04,.02,.05,.03,.02,0,.03,.02,.04,.03,.02,.02,.05,.04,.07,.07h0s.02,.04,.04,.06c0,0,0,0,0,.01,0,0,0,0,0,.01,.02,.04,.03,.08,.04,.12Z"
        />
        <path
          fill="currentColor"
          d="M4.09,154.44s.01,.05,.02,.07c0-.04-.02-.08-.04-.12l.02,.05Z"
        />
        <path
          fill="currentColor"
          d="M4.1,154.68s-.02,.08-.02,.11c-.03,.03-.05,.06-.08,.08,.05-.06,.08-.12,.1-.2Z"
        />
        <path
          fill="currentColor"
          d="M4.08,154.79c-.08,.37-.17,.75-.25,1.12-.15-.3-.3-.59-.45-.9,.16,.08,.41,.1,.62-.14,.02-.03,.05-.06,.08-.08Z"
        />
        <path
          fill="currentColor"
          d="M3.33,154.29c.27-.31,.55-.62,.84-.93-.09,.43-.14,.74-.16,.94-.02-.02-.04-.05-.07-.07-.01-.01-.03-.02-.04-.03-.02-.01-.04-.02-.05-.03-.02-.01-.04-.02-.06-.02,0,0-.01,0-.02,0-.01,0-.03,0-.04,0-.04,0-.09,0-.13,0-.01,0-.03,0-.04,0-.03,0-.05,.02-.08,.03h0l-.06,.04s-.03,.02-.04,.04c-.02,.02-.03,.03-.04,.04,0,0,0,0,0-.01Z"
        />
        <path fill="currentColor" d="M4.05,154.36s0,0,0,.01c0,0,0,0,0-.01Z" />
        <path fill="currentColor" d="M4.01,154.3s0,0,0,0h0Z" />
        <path
          fill="currentColor"
          d="M3.94,154.23s-.03-.02-.04-.03c.02,0,.03,.02,.04,.03Z"
        />
        <path
          fill="currentColor"
          d="M3.84,154.18s-.04-.02-.06-.02c.02,0,.04,0,.06,.02Z"
        />
        <path
          fill="currentColor"
          d="M3.76,154.15s.01,0,.02,0h-.06s.03-.01,.04,0Z"
        />
        <path
          fill="currentColor"
          d="M3.66,155.03c.12,0,.25-.05,.34-.15-.21,.24-.47,.22-.62,.14-.04-.08-.08-.16-.12-.24,0,0,0-.01,0-.02,.03,.06,.06,.11,.12,.15,.08,.08,.19,.11,.29,.11Z"
        />
        <path
          fill="currentColor"
          d="M3.72,154.14s-.09,0-.13,0c.04,0,.09,0,.13,0Z"
        />
        <path
          fill="currentColor"
          d="M3.55,154.15s-.05,.02-.08,.03c.02-.02,.05-.02,.08-.03Z"
        />
        <path
          fill="currentColor"
          d="M3.41,154.22s-.03,.02-.04,.04c.01-.02,.03-.03,.04-.04Z"
        />
        <path
          fill="currentColor"
          d="M3.38,155.02s0,0,0,0c-.08-.05-.16-.09-.21-.25,.02-.08,.04-.16,.06-.25,0,.05,0,.1,0,.15,0,.03,.01,.05,.02,.08,0,0,0,.01,0,.01,0,0,0,.01,0,.02,.04,.08,.08,.16,.12,.24Z"
        />
        <path fill="currentColor" d="M3.28,154.35v.02s0-.02,0-.02Z" />
        <path
          fill="currentColor"
          d="M3.22,154.67s.02,.06,.02,.08c-.01-.03-.02-.05-.02-.08Z"
        />
        <path
          fill="currentColor"
          d="M3.23,154.48s0-.02,0-.02c0,0,0,.02,0,.02Z"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
