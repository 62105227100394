import { Fragment, useEffect, useState } from 'react';

interface Props {
  countTo?: number;
  duration?: number;
}

const easeOutQuad = (t: number) => t * (2 - t);
const frameDuration: number = 1000 / 60;

const CountUp = (props: Props) => {
  let countTo: number = 100;
  if (props.countTo) {
    countTo = props.countTo;
  }

  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let frame: number = 0;
    const animationDuration: number = 2000;
    let totalFrames: number = 0;
    if (props.duration) {
      totalFrames = Math.round(props.duration / frameDuration);
    } else {
      totalFrames = Math.round(animationDuration / frameDuration);
    }

    const counter = setInterval(() => {
      frame++;
      const progress: number = easeOutQuad(frame / totalFrames);
      setCount(countTo * progress);

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }, [props.duration, countTo]);

  return <Fragment>{Math.floor(count)}</Fragment>;
};

export default CountUp;
