import cx from 'classnames';
import { FC } from 'react';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';

type SpacerSize = '0.5x' | '1x' | '2x' | '3x' | '4x';

interface Props {
  size: SpacerSize;
  backgroundStyle: BackgroundStyle;
}

const getSizeClass = (size: SpacerSize): string => {
  if (size === '0.5x') {
    return 'SpacerBlock--half';
  }

  return 'SpacerBlock--' + size;
};

const SpacerBlock: FC<Props> = (props: Props) => {
  return (
    <div className={cx('SpacerBlock', getSizeClass(props.size))}>
      <BackgroundStyleComponent background={props.backgroundStyle} />
    </div>
  );
};

export default SpacerBlock;
