import {
  Maybe,
  MessagingAPIForBrowser,
  MessagingAPIForBrowserSettings,
} from 'lunr-core/browser';

let instance: Maybe<PublicMessagingAPIForBrowser> = null;

/**
 * A client-side helper for obtaining a messaging reference
 * to send messages to the queue.
 */
export const getMessagingAPIForBrowser = (): PublicMessagingAPIForBrowser => {
  if (typeof window === 'undefined') {
    throw new Error(
      'Do not call this from the server. Use the getMessagingAPIForServer() helper instead.'
    );
  }

  if (instance) {
    return instance;
  }

  instance = new PublicMessagingAPIForBrowser({
    apiKey: process.env.NEXT_PUBLIC_API_KEY!,
    getProgressEndpoint: '/api/messaging/get-progress',
    sendEndpoint: '/api/messaging/send',
  });

  return instance;
};

/**
 * A wrapper around the MessagingAPI that includes the borrowers-specific helpers.
 */
export class PublicMessagingAPIForBrowser extends MessagingAPIForBrowser {
  constructor(settings: MessagingAPIForBrowserSettings) {
    super(settings);
  }
}
