/**
 * @description Takes an existing Contentful image URL and appends the resize commands to it. Returns the new URL to the resized image.
 * @param {string} imageURL The Contentful image URL.
 * @param {number} newWidth The width to resize the image to.
 * @returns {string} The url to the new resized image
 */
const contentfulImageResizer = (imageURL: string, newWidth: number): string => {
  // Was hoping that this method could be exported from the ./contentfulImageProvider, but that is not possible since this method is predominantely called with client-side code.
  return imageURL + '?w=' + encodeURIComponent(newWidth);
};

export default contentfulImageResizer;
