import { FC, Fragment } from 'react';

interface Props {
  container: string;
}

const GoogleTagManagerHeadScripts: FC<Props> = (props: Props) => {
  return (
    <Fragment>
      <script
        async
        src={'https://www.googletagmanager.com/gtag/js?id=' + props.container}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${props.container}');`,
        }}
      ></script>
    </Fragment>
  );
};

export default GoogleTagManagerHeadScripts;
