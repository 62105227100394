import { FC, Fragment } from 'react';

interface Props {
  container: string;
}

const GoogleTagManagerBodyScripts: FC<Props> = (props: Props) => {
  return (
    <Fragment>
      <noscript>
        <iframe
          src={'https://www.googletagmanager.com/ns.html?id=' + props.container}
          width="0"
          height="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </Fragment>
  );
};

export default GoogleTagManagerBodyScripts;
