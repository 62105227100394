import cx from 'classnames';
import React from 'react';
import { BackgroundStyle } from '../background-style';

export enum ArcVariant {
  One,
  Two,
  Three,
}

export const getArcVariantFromString = (value: string): ArcVariant => {
  switch (value) {
    case '1':
      return ArcVariant.One;
    case '2':
      return ArcVariant.Two;
    case '3':
      return ArcVariant.Three;
    default:
      console.log(
        'Unsupported arc variant string `' +
          value +
          '`! Defaulting to variant #1.'
      );
      return ArcVariant.One;
  }
};

interface Props {
  className?: string;
  backgroundStyle: BackgroundStyle;
  children?: React.ReactNode;
  variant: ArcVariant;
}

interface State {}

interface Vector2 {
  x: number;
  y: number;
}

interface Arc {
  origin: Vector2; // The percentage of the total canvas size to shift the arc
  angleShape: number;
  angleColor: number;
  scale: Vector2;
  scaleColor: Vector2; // How large to draw the `missing` section of the arc. x is the minimum size, y is the maximum size.
  velocityShape: number;
  velocityColor: number;
}

interface ColorBand {
  start: string;
  end: string;
}

interface ColorTheme {
  backgroundColor: string;
  colorBand: ColorBand;
}

const THEME_WHITE_BACKGROUND: ColorTheme = {
  backgroundColor: '#ffffff',
  colorBand: {
    start: 'rgba(255, 255, 255, 0.0)',
    end: '#5E00E2',
  },
};

const THEME_PURPLE_BACKGROUND: ColorTheme = {
  backgroundColor: '#150130',
  colorBand: {
    start: 'rgba(21, 1, 48, 0.0)',
    end: '#5E00E2',
  },
};

export default class AnimatedArcs extends React.Component<Props, State> {
  _rootRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  _canvasRef: React.RefObject<HTMLCanvasElement> =
    React.createRef<HTMLCanvasElement>();
  _context: CanvasRenderingContext2D | null = null;
  _running: boolean = false;
  _arcs: Array<Arc> = [];

  componentDidMount = (): void => {
    if (!this._canvasRef.current) {
      console.warn('AnimatedArcs componentDidMount but no element ref found!');
      return;
    }

    this.sizeCanvasToArea();
    this._context = this._canvasRef.current.getContext('2d');

    requestAnimationFrame(this.onAnimationLoop);
    window.addEventListener('resize', this.onWindowResize);

    this._running = true;
  };

  componentWillUnmount = (): void => {
    window.removeEventListener('resize', this.onWindowResize);
    this._running = false;
  };

  render = () => {
    const isPurple: boolean =
      this.props.backgroundStyle.toLowerCase().indexOf('purple') >= 0;
    return (
      <div
        ref={this._rootRef}
        className={cx(
          'AnimatedArcs',
          isPurple && 'AnimatedArcs--purple',
          this.props.className
        )}
      >
        <canvas className="AnimatedArcs--canvas" ref={this._canvasRef} />
        <div className="AnimatedArcs--inner">{this.props.children}</div>
      </div>
    );
  };

  onAnimationLoop = () => {
    if (this._canvasRef.current && this._context) {
      const isWhite: boolean =
        this.props.backgroundStyle.toLowerCase().indexOf('white') >= 0;
      const theme: ColorTheme = isWhite
        ? THEME_WHITE_BACKGROUND
        : THEME_PURPLE_BACKGROUND;

      // <background>
      this._context.save();
      this._context.fillStyle = theme.backgroundColor;
      this._context.fillRect(
        0,
        0,
        this._canvasRef.current.width,
        this._canvasRef.current.height
      );
      this._context.restore();
      // </background>

      // const colorBand:ColorBand = (isWhite ? colorBandWhiteBackground : colorBandPurpleBackground)
      const arcs = getArcs(this.props.variant);
      const size: Vector2 = {
        x: this._canvasRef.current.width,
        y: this._canvasRef.current.height,
      };
      const center: Vector2 = {
        x: size.x / 2,
        y: size.y / 2,
      };

      for (const arc of arcs) {
        // const isLandscape:boolean = this._canvasRef.current.width > this._canvasRef.current.height
        const ellipse: EllipseShape = {
          origin: {
            x: center.x + arc.origin.x * size.x,
            y: center.y + arc.origin.y * size.y,
          },
          radius: {
            x: center.x * arc.scale.x,
            y: center.y * arc.scale.y,
          },
          rotation: arc.angleShape,
        };
        // const area:Rect = getEllipseArea(ellipse) // For debug.
        const pointA: Vector2 = getPointOnEllipse(ellipse, arc.angleColor);

        const gradientSize: number = Math.max(
          window.innerWidth,
          window.innerHeight
        );
        const gradient = this._context.createRadialGradient(
          pointA.x,
          pointA.y,
          gradientSize * arc.scaleColor.x, // The minimum size of the empty area
          pointA.x,
          pointA.y,
          gradientSize * arc.scaleColor.y // The maximum sie of the empty area.
        );
        // gradient.addColorStop(0, '#ff0000')
        // gradient.addColorStop(1, '#00ff00')
        gradient.addColorStop(0, theme.colorBand.start);
        gradient.addColorStop(0.1, theme.colorBand.start);
        gradient.addColorStop(0.9, theme.colorBand.end);
        gradient.addColorStop(1, theme.colorBand.end);

        // <debugOutline>
        // this._context.save()
        // this._context.fillStyle = gradient
        // this._context.fillRect(area.x, area.y, area.width, area.height)
        // this._context.lineWidth = 1
        // this._context.strokeStyle = 'red'
        // this._context.strokeRect(area.x, area.y, area.width, area.height)
        // this._context.restore()
        // </debugOutline>

        // <dots>
        // this._context.save()
        // this._context.strokeStyle = 'black'
        // this._context.fillStyle = '#ff0000' // PointA
        // this._context.fillRect(pointA.x-3, pointA.y-3, 6, 6)
        // this._context.strokeRect(pointA.x-3, pointA.y-3, 6, 6)

        // this._context.fillStyle = '#00ff00' // PointB
        // this._context.fillRect(pointB.x-3, pointB.y-3, 6, 6)
        // this._context.strokeRect(pointB.x-3, pointB.y-3, 6, 6)
        // this._context.restore()
        // </dots>

        // <arcLine>
        this._context.save();
        this._context.lineWidth = 1;
        // this._context.strokeStyle = 'red'
        this._context.strokeStyle = gradient;
        this._context.beginPath();
        this._context.ellipse(
          ellipse.origin.x,
          ellipse.origin.y,
          ellipse.radius.x,
          ellipse.radius.y,
          ellipse.rotation,
          0, // Start angle
          Math.PI * 2 // End angle. Full circle.
        );
        this._context.stroke();
        this._context.restore();
        // <arcLine>

        arc.angleShape += arc.velocityShape * 0.001;
        arc.angleColor += arc.velocityColor * 0.0024;
      }
    }

    if (this._running) {
      requestAnimationFrame(this.onAnimationLoop);
    }
  };

  onWindowResize = () => {
    this.sizeCanvasToArea();
  };

  sizeCanvasToArea = () => {
    if (!this._canvasRef.current || !this._rootRef.current) {
      return;
    }

    const rect: DOMRect = this._rootRef.current.getBoundingClientRect();
    this._canvasRef.current.width = rect.width;
    this._canvasRef.current.height = rect.height;
  };
}

interface EllipseShape {
  origin: Vector2;
  radius: Vector2;
  rotation: number;
}

const getPointOnEllipse = (shape: EllipseShape, angle: number): Vector2 => {
  const x = shape.origin.x + shape.radius.x * Math.cos(angle);
  const y = shape.origin.y + shape.radius.y * Math.sin(angle);
  const x1 =
    (x - shape.origin.x) * Math.cos(shape.rotation) -
    (y - shape.origin.y) * Math.sin(shape.rotation) +
    shape.origin.x;
  const y1 =
    (x - shape.origin.x) * Math.sin(shape.rotation) +
    (y - shape.origin.y) * Math.cos(shape.rotation) +
    shape.origin.y;
  return {
    x: x1,
    y: y1,
  };
};

type ArcSet = Array<Arc>;
const arcSets: Array<ArcSet> = [
  // <homepage>
  [
    {
      origin: { x: 0, y: 0 },
      angleShape: -Math.PI / 8,
      angleColor: 0.7,
      scale: { x: 1.1, y: 1.1 },
      scaleColor: { x: 0.2, y: 0.5 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
    {
      origin: { x: 0, y: 0 },
      angleShape: -Math.PI / 8,
      angleColor: 0.4,
      scale: { x: 1.17, y: 1.17 },
      scaleColor: { x: 0.2, y: 0.5 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
    {
      origin: { x: 0, y: 0 },
      angleShape: -Math.PI / 8,
      angleColor: 0.0,
      scale: { x: 1.27, y: 1.27 },
      scaleColor: { x: 0.2, y: 0.5 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
  ],
  // </homepage>
  // <portfolio>
  [
    {
      origin: { x: 0.0, y: -0.75 },
      angleShape: 0.0,
      angleColor: 1.0,
      scale: { x: 2.0, y: 2.15 },
      scaleColor: { x: 0.5, y: 1.0 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
    {
      origin: { x: 0.0, y: -0.77 },
      angleShape: -0.02,
      angleColor: 1.5,
      scale: { x: 2.0, y: 2.15 },
      scaleColor: { x: 0.5, y: 1.0 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
    {
      origin: { x: 0.0, y: -0.76 },
      angleShape: -Math.PI / 64,
      angleColor: 0.5,
      scale: { x: 2.3, y: 2.3 },
      scaleColor: { x: 0.5, y: 1.0 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
  ],
  // </portfolio>
  [
    {
      origin: { x: 0.0, y: 0.75 },
      angleShape: -0.02,
      angleColor: 1.0,
      scale: { x: 2.0, y: 2.15 },
      scaleColor: { x: 0.5, y: 1.0 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
    {
      origin: { x: 0.0, y: 0.77 },
      angleShape: 0.0,
      angleColor: 1.5,
      scale: { x: 2.0, y: 2.15 },
      scaleColor: { x: 0.5, y: 1.0 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
    {
      origin: { x: -0.05, y: 0.35 },
      angleShape: -Math.PI / 8,
      angleColor: 0.0,
      scale: { x: 1.5, y: 1.5 },
      scaleColor: { x: 0.5, y: 1.0 },
      velocityShape: 0.0,
      velocityColor: 1.0,
    },
  ],
];

const getArcs = (variant: ArcVariant): Array<Arc> => {
  switch (variant) {
    case ArcVariant.One:
      return arcSets[0];
    case ArcVariant.Two:
      return arcSets[1];
    case ArcVariant.Three:
      return arcSets[2];
    default:
      throw new Error('Unsupported variant');
  }
};
