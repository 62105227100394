import { BackgroundStyle } from '../components/background-style';
import { getBackgroundStyle } from '../components/background-style/BackgroundStyle';
import { Block, Page } from '../content-provider';

export default (page: Page | null | undefined): BackgroundStyle => {
  if (!page || page.blocks.length <= 0) {
    return BackgroundStyle.White;
  }

  const firstBlock = page.blocks[0] as Block<{
    backgroundStyle: BackgroundStyle;
  }>;

  if (!firstBlock.data.backgroundStyle) {
    return BackgroundStyle.White;
  }

  return getBackgroundStyle(firstBlock.data.backgroundStyle);
};
