import cx from 'classnames';
import { FC } from 'react';
import BlockViewportAnimation from '../../components/block-viewport-animation';
import LogoIcon from '../../components/logo-icon';

interface ProcessBlockItem {
  title: string;
  subtitle: string;
  description: string;
}

interface Props {
  title: string;
  items: Array<ProcessBlockItem>;
}

const ProcessBlock: FC<Props> = (props: Props) => {
  return (
    <div className={cx('ProcessBlock')}>
      <div className="container">
        <BlockViewportAnimation className="ProcessBlock--inner">
          <h2 className="h1 ProcessBlock--title typography--subhead hidden-below-desk">
            {props.title}
          </h2>

          <div className="ProcessBlock--items">
            <div className="ProcessBlock--itemsInner">
              {props.items &&
                props.items.map((item: ProcessBlockItem, index: number) => {
                  return (
                    <div
                      key={'ProcessBlock--item' + (index + 1)}
                      className={cx(
                        'ProcessBlock--item',
                        'ProcessBlock--item' + (index + 1)
                      )}
                    >
                      <h2 className="ProcessBlock--itemTitle h3">
                        {item.title}
                      </h2>
                      <h3 className="ProcessBlock--itemSubtitle typography--subheadAlt">
                        {item.subtitle}
                      </h3>
                      <p className="ProcessBlock--itemDescription marginless">
                        {item.description}
                      </p>
                    </div>
                  );
                })}

              <div className={cx('ProcessBlock--item ProcessBlock--itemLogo')}>
                <LogoIcon className="ProcessBlock--itemLogoIcon" />
              </div>
            </div>
          </div>
        </BlockViewportAnimation>
      </div>
    </div>
  );
};

export default ProcessBlock;
