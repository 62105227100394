import cx from 'classnames';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';
import { NavigationLink } from '../../content-provider';
import Anchor from '../anchor';

interface Props {
  className?: string;
  classNameItem?: string;
  classNameAnchor?: string;
  classNameAnchorActive?: string;
  items: Array<NavigationLink>;
  children?: ReactNode;
  onClick?: () => void;
}

const NavigationLinkList: FC<Props> = (props: Props) => {
  const router = useRouter();

  return (
    <ul className={cx(props.className)}>
      {props.items &&
        props.items.map((item: NavigationLink) => {
          return (
            <li key={item.url} className={cx(props.classNameItem)}>
              <Anchor
                className={cx(
                  props.classNameAnchor,
                  router.pathname === item.url && props.classNameAnchorActive
                )}
                link={item}
                onClick={props.onClick}
              />
            </li>
          );
        })}

      {props.children}
    </ul>
  );
};

export default NavigationLinkList;
