import React, { FC } from 'react';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import BlockViewportAnimation from '../../components/block-viewport-animation';

type Size = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Alignment = 'Left' | 'Center';

interface Props {
  text: string;
  size: Size;
  alignment: Alignment;
  backgroundStyle: BackgroundStyle;
}

const HeadlineBlock: FC<Props> = (props: Props) => {
  return (
    <div className="HeadlineBlock">
      <BackgroundStyleComponent
        background={props.backgroundStyle}
        className="HeadlineBlock--inner"
      >
        <BlockViewportAnimation className="container">
          {renderHeader(props.size, props.alignment, props.text)}
        </BlockViewportAnimation>
      </BackgroundStyleComponent>
    </div>
  );
};

const renderHeader = (size: Size, alignment: Alignment, text: string) => {
  return React.createElement(
    size,
    {
      className: getAlignmentClass(alignment),
    },
    text
  );
};

const getAlignmentClass = (alignment: Alignment): string => {
  switch (alignment) {
    case 'Center':
      return 'text--center';
    default:
      return 'text--left';
  }
};

export default HeadlineBlock;
