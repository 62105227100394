import cx from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
  html: string;
}

const WYSIWYG: FC<Props> = (props) => {
  const cleanedHTML: string = props.html.replace('&amp;', '&'); // Contenful likes to HTML encode ampersands, even when we're literally trying to use html codes. Swap them back.
  return (
    <div
      className={cx('WYSIWYG', props.className)}
      dangerouslySetInnerHTML={{ __html: cleanedHTML }}
    />
  );
};

export default WYSIWYG;
