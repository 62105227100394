import jquery from 'jquery';

interface WatchArea {
  element: HTMLElement;
  jquerySelector: string;
}

const areas: Array<WatchArea> = [];

export default (element: HTMLElement, jquerySelector: string) => {
  const area: WatchArea = {
    element: element,
    jquerySelector: jquerySelector,
  };
  makeSameHeight(area);
  areas.push(area);
};

const refreshHeights = (): void => {
  for (const area of areas) {
    makeSameHeight(area);
  }
};

const makeSameHeight = (area: WatchArea): void => {
  const $items = jquery(area.element).find(area.jquerySelector);
  let maxHeight: number = NaN;
  for (const item of $items) {
    const $item = jquery(item);
    const height: number = $item.innerHeight() || 0;
    if (isNaN(maxHeight) || height > maxHeight) {
      maxHeight = height;
    }
  }

  $items.height(maxHeight);
};

if (typeof window !== 'undefined') {
  window.addEventListener('resize', refreshHeights);
}
