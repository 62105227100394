import cx from 'classnames';
import {
  axios,
  Maybe,
  MessageProgressComplete,
  MessageProgressFailed,
  MessageStatus,
  MessagingAPIForBrowser,
} from 'lunr-core/browser';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import {
  ProcessApplicationFormInput,
  ProcessApplicationFormResponse,
} from '../../../pages/api/process-application-form';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import ViewportObserverOneTime from '../../components/viewport-observer-onetime';
import { Image } from '../../content-provider';
import { getMessagingAPIForBrowser } from '../../getMessagingAPIForBrowser';
import ApplicationForm from './ApplicationForm';

interface Props {
  title: string;
  subtitle: string;
  backgroundImage: Image;
  backgroundStyle: BackgroundStyle;
}

interface State {
  submitted: boolean;
}

export default class ApplicationFormBlock extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  render = () => {
    const titleHTML: string = this.props.title
      .replace('{', '<span class="ApplicationFormBlock--titleAlt">')
      .replace('}', '</span>');

    return (
      <div className={cx('ApplicationFormBlock')}>
        <BackgroundStyleComponent
          className="ApplicationFormBlock--inner"
          background={this.props.backgroundStyle}
        >
          <ViewportObserverOneTime className="container">
            <div className="gw">
              <div className="g hand-one-whole desk-three-fifths">
                <AnimateHeight height={this.state.submitted ? 0 : 'auto'}>
                  <React.Fragment>
                    <div className="ApplicationFormBlock--header">
                      {this.props.title && (
                        <h2
                          className="ApplicationFormBlock--title"
                          dangerouslySetInnerHTML={{ __html: titleHTML }}
                        />
                      )}
                      {this.props.subtitle && (
                        <h6
                          className="ApplicationFormBlock--subtitle"
                          dangerouslySetInnerHTML={{
                            __html: this.props.subtitle,
                          }}
                        />
                      )}
                    </div>

                    <ApplicationForm onSubmit={this.onSubmit} />
                  </React.Fragment>
                </AnimateHeight>

                <AnimateHeight height={this.state.submitted ? 'auto' : 0}>
                  <div className="ApplicationFormBlock--header">
                    <h2 className="ApplicationFormBlock--title">
                      Thanks for your submission!
                    </h2>
                    <h6 className="ApplicationFormBlock--subtitle">
                      We&apos;ll contact you soon.
                    </h6>
                  </div>
                </AnimateHeight>
              </div>
            </div>
          </ViewportObserverOneTime>
        </BackgroundStyleComponent>
      </div>
    );
  };

  onSubmit = async (values: ProcessApplicationFormInput): Promise<boolean> => {
    let response = null;
    try {
      response = await axios.post<ProcessApplicationFormResponse>(
        '/api/process-application-form',
        values,
        {
          headers: {
            'api-key': process.env.NEXT_PUBLIC_API_KEY!,
          },
        }
      );
    } catch (err) {
      console.warn('Error submitting form data!', err);
    }

    if (!response || response.status !== 200) {
      return false;
    }

    if (!response || response.status !== 200 || !response.data.messageID) {
      return false;
    }

    const messagingAPI: MessagingAPIForBrowser = getMessagingAPIForBrowser();
    const progress: Maybe<MessageProgressComplete | MessageProgressFailed> =
      await messagingAPI.waitForProgress(response.data.messageID);
    if (!progress || progress.status !== MessageStatus.COMPLETED) {
      return false;
    }

    this.setState({ submitted: true }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    return true;
  };
}
