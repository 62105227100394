import cx from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';

export type BackgroundStyleString =
  | 'Purple'
  | 'White'
  | 'Purple Top'
  | 'White Top';

export enum BackgroundStyle {
  Purple = 'Purple',
  White = 'White',
  PurpleTop = 'Purple Top',
  WhiteTop = 'White Top',
}

interface Props {
  className?: string;
  background: BackgroundStyleString;
  children?: ReactNode;
  style?: CSSProperties;
}

const BackgroundStyleComponent: FC<Props> = (props: Props) => {
  return (
    <div
      className={cx(
        'BackgroundStyle',
        getBackgroundStyleClassName(props.background, 'BackgroundStyle')
      )}
    >
      <div
        className={cx(
          props.className,
          props.className &&
            getBackgroundStyleClassName(props.background, props.className),
          'BackgroundStyle--inner'
        )}
        style={props.style}
      >
        {props.children}
      </div>
    </div>
  );
};

export const getBackgroundStyle = (
  value: BackgroundStyleString
): BackgroundStyle => {
  switch (value) {
    case 'Purple':
      return BackgroundStyle.Purple;
    case 'White':
      return BackgroundStyle.White;
    case 'Purple Top':
      return BackgroundStyle.PurpleTop;
    case 'White Top':
      return BackgroundStyle.WhiteTop;
    default:
      console.warn(
        `getBackgroundStyle called with an unknown value "${String(value)}"!`
      );
      return BackgroundStyle.White;
  }
};

const getBackgroundStyleClassName = (
  style: BackgroundStyleString,
  prefix: string
): string | null => {
  switch (style) {
    case 'Purple':
      return prefix + '--purple';
    case 'White':
      return prefix + '--white';
    case 'Purple Top':
      return prefix + '--purpleTop';
    case 'White Top':
      return prefix + '--whiteTop';
    default:
      return null;
  }
};

export default BackgroundStyleComponent;
