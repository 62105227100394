import { Component, FunctionComponent, ReactNode } from 'react';
import { Block } from '../content-provider';
import ApplicationFormBlock from './application-form-block';
import CallToActionBlock from './call-to-action-block';
import EarlyAccessRequestBlock from './early-access-request-block';
import EmployeeBlock from './employee-block';
import FullImageBlock from './full-image-block';
import HalfHalfBlock from './half-half-block';
import HeadlineBlock from './headline-block';
import HeroBlock from './hero-block';
import LogoTickerBlock from './logo-ticker-block';
import ProcessBlock from './process-block';
import SpacerBlock from './spacer-block';
import StatisticsBlock from './statistics-block/StatisticsBlock';
import ThreeUpBlock from './three-up-block/ThreeUpBlock';
import TwoUpBlock from './two-up-block';

interface OwnProps {
  block: Block;
  children?: ReactNode;
}

interface ReduxProps {}
interface DispatchProps {}

type Props = OwnProps & ReduxProps & DispatchProps;

interface State {}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any  -- Disable due to a huge refactor that would be needed to treat this Prop type */
type Components<P extends object = any> =
  | FunctionComponent<P>
  | typeof Component<P>;

const namesToComponents: Record<string, Components> = {
  // <alphabetical> Please keep this list alphabetical so we can easily find things.
  applicationFormBlock: ApplicationFormBlock,
  callToActionBlock: CallToActionBlock,
  earlyAccessRequestBlock: EarlyAccessRequestBlock,
  employeeBlock: EmployeeBlock,
  fullImageBlock: FullImageBlock,
  halfHalfBlock: HalfHalfBlock,
  headlineBlock: HeadlineBlock,
  homepageHeroBlock: HeroBlock,
  heroBlock: HeroBlock,
  logoTickerBlock: LogoTickerBlock,
  processBlock: ProcessBlock,
  spacerBlock: SpacerBlock,
  statisticsBlock: StatisticsBlock,
  twoUpBlock: TwoUpBlock,
  threeUpBlock: ThreeUpBlock,
  // </alphabetical>
};

export default class BlockFactory extends Component<Props, State> {
  render = () => {
    if (!this.props.block) {
      console.warn('BlockFactory called, but no block supplied!');
      return null;
    }

    if (!this.props.block.name) {
      console.warn(
        'BlockFactory called, but block had no name!',
        this.props.block
      );
      return null;
    }

    const Component = namesToComponents[this.props.block.name];
    if (!Component) {
      console.warn(
        'BlockFactory called with an unknown block!',
        this.props.block
      );
      return null;
    }

    return (
      <Component key={this.props.block.id} {...this.props.block.data}>
        {this.props.children}
      </Component>
    );
  };
}
