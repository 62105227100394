import cx from 'classnames';
import React, { FC, useEffect } from 'react';
import Anchor from '../../components/anchor';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import BlockViewportAnimation from '../../components/block-viewport-animation';
import { Image, Link } from '../../content-provider';
import makeSameHeight from '../../utils/makeSameHeight';

interface TwoUpBlockItem {
  imageIsIcon: boolean;
  image: Image;
  title: string;
  description: string;
  link: Link | null;
}

interface Props {
  items: Array<TwoUpBlockItem>;
  backgroundStyle: BackgroundStyle;
}

const TwoUpBlock: FC<Props> = (props: Props) => {
  const delay = (ms: number | undefined) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const _elementRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    async function resizeElements() {
      await delay(500);

      if (!_elementRef.current) {
        return;
      }

      makeSameHeight(_elementRef.current, '.TwoUpBlock--item');
    }
    // putting into function to incorporate delay, the cards were not resizing correctly without a page load.
    resizeElements().catch((err) => console.error(err));
  });

  return (
    <div ref={_elementRef} className="TwoUpBlock">
      <BackgroundStyleComponent
        background={props.backgroundStyle}
        className={cx('TwoUpBlock--inner')}
      >
        <BlockViewportAnimation className={cx('container')}>
          <div className="gw">
            {props.items &&
              props.items.map((item: TwoUpBlockItem) => {
                return (
                  <div key={item.title} className="g desk-one-half">
                    <div className="TwoUpBlock--item">
                      <div>
                        <img
                          className={cx(
                            'TwoUpBlock--itemImage',
                            item.imageIsIcon && 'itemImage--icon'
                          )}
                          src={item.image.url}
                          alt={item.image.alt}
                        />
                      </div>

                      {item.title && (
                        <h6
                          className="TwoUpBlock--heading"
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      )}

                      {item.description && (
                        <p className="TwoUpBlock--description">
                          {item.description}
                        </p>
                      )}

                      {item.link && <Anchor link={item.link} />}
                    </div>
                  </div>
                );
              })}
          </div>
        </BlockViewportAnimation>
      </BackgroundStyleComponent>
    </div>
  );
};

export default TwoUpBlock;
