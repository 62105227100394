import assert from 'assert';

/**
 * Converts a Date instance into ISO 8601 format.
 *
 * @param {Date} date The date to convert
 * @returns A string formatted date in the pattern of ISO 8601 ('2023-05-26 14:58:04')
 */
const dateToISO8601 = (date: Date): string => {
  if (!date) {
    throw new Error('date is a required argument');
  }

  // https://stackoverflow.com/a/74387204/6100384
  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export default dateToISO8601;

export const tests = () => {
  testValidFormat();
};

const testValidFormat = () => {
  const value: Date = new Date(2023, 4, 26, 12, 0, 0, 0);
  const output: string = dateToISO8601(value);
  assert.ok(isISO8601Format(output));
};

const isISO8601Format = (value: string): boolean => {
  if (!value) {
    return false;
  }

  if (value.length !== 19) {
    return false;
  }

  const tokens: Array<string> = value.split(' ');
  if (tokens.length !== 2) {
    return false;
  }

  const firstToken: string = tokens[0];
  if (firstToken.length !== 10) {
    return false;
  }

  const yearMonthDayTokens: Array<string> = firstToken.split('-');
  if (yearMonthDayTokens.length !== 3) {
    return false;
  }

  const year: string = yearMonthDayTokens[0];
  if (year.length !== 4 || isNaN(parseInt(year))) {
    return false;
  }

  const month: string = yearMonthDayTokens[1];
  if (month.length !== 2 || isNaN(parseInt(month))) {
    return false;
  }

  const date: string = yearMonthDayTokens[2];
  if (date.length !== 2 || isNaN(parseInt(date))) {
    return false;
  }

  const hourMinuteSecond: string = tokens[1];
  if (hourMinuteSecond.length !== 8) {
    return false;
  }

  const hourMinuteSecondTokens: Array<string> = hourMinuteSecond.split(':');
  if (hourMinuteSecondTokens.length !== 3) {
    return false;
  }

  const hour: string = hourMinuteSecondTokens[0];
  if (hour.length !== 2 || isNaN(parseInt(hour))) {
    return false;
  }

  const minute: string = hourMinuteSecondTokens[1];
  if (minute.length !== 2 || isNaN(parseInt(minute))) {
    return false;
  }

  const second: string = hourMinuteSecondTokens[2];
  if (second.length !== 2 || isNaN(parseInt(second))) {
    return false;
  }

  return true;
};
